import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import axios from "axios";
import {getEncryptedLocalStorage} from "../../utils/utils";


// Get All Role Data
export const getAllRole = createAsyncThunk('RoleAdmin/getAllData', async () => {
  try {
    let getAllData = await axios.get('/masters/getRole').then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getAllData

  } catch (error) {
  }
})

// Get All Subject Data
export const getAllSubject = createAsyncThunk('Masters/getAllSubjectData', async (payload) => {
  try {
    let getAllData = await axios.post('/masters/getSubject', payload).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getAllData

  } catch (error) {
  }
})
// Get All difficulty Data
export const getAllDifficulty = createAsyncThunk('Masters/getAllDifficultLevelData', async () => {
  try {
    let getAllData = await axios.get('/masters/getDifficultyLevel').then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getAllData

  } catch (error) {
  }
})
// Get All difficulty Data
export const getAllChapter = createAsyncThunk('Masters/getAllChapterData', async () => {
  try {
    let getAllData = await axios.get('/masters/getChapter').then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getAllData

  } catch (error) {
  }
})


//  get All chapter by Subject Id 
export const getChapterBySubject = createAsyncThunk("MasterChapters/BySubjectId", async (payload, thunk) => {
  try {
    let url = ''
    let roleId = getEncryptedLocalStorage("roleId");

    if (payload.obj?.status?.statusId) {

      if (roleId == 5) {

        url = `/masters/getChapterBySubject/${payload.url.examId}/${payload.url.subId}`
      }
      else if (roleId == 4) {
        if (payload.obj.status.name == 'all') {
          url = `/masters/getChapterBySubject/${payload.url.examId}/${payload.url.subId}?filterType=all`

        } else {
          url = `/masters/getChapterBySubject/${payload.url.examId}/${payload.url.subId}?statusId=${payload.obj.status.statusId}&filterType=${payload.obj.status.name}`

        }

      }
      else if (roleId == 1 || roleId == 3) {
        // debugger
        url = `/masters/getChapterBySubject/${payload.url.examId}/${payload.url.subId}?statusId=${payload.obj.status.statusId}&filterType=${payload.obj.status.name}`

      }
    }
    else {
      if (roleId == 4) {
        url = `/masters/getChapterBySubject/${payload.url.examId}/${payload.url.subId}?filterType=all`

      }
      else {
        // debugger
        url = `/masters/getChapterBySubject/${payload.url.examId}/${payload.url.subId}?statusId=2&filterType=approved`

      }
    }


    let getAll = await axios.post(url, payload.obj)
      .then(async (res) => {
        if (res.data.success) {
          thunk.dispatch(addBreadCrumbData(res.data.data?.breadcrumbs))

          return res.data.data
        }
      })
    let obj = {
      id: payload.url.subId,
      data: getAll
    }

    return obj

  } catch (error) {
  }
})

//  get All Exam  
export const getAllExam = createAsyncThunk("Masters/Exam", async (payload) => {
  try {
    let getAll = await axios.post('/masters/getExam', payload).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getAll

  } catch (error) {
  }
})

//  get All Subject by Exam Id  
export const getSubjectByExamId = createAsyncThunk("Masters/getSubjectByExamId", async (payload, thunk) => {
  try {
    let roleId = getEncryptedLocalStorage("roleId")
    let apiUrl = ""

    if (roleId == 4 || roleId == 5) {
      apiUrl = `/masters/getSubjectByExamId/${payload?.examId}`
    }
    else if (roleId == 1) {
      if (payload?.obj?.status?.statusId != -1) {
        if (payload.obj.status.name == 'all') {
          apiUrl = `/masters/getSubjectByExamId/${payload?.examId}?filterType=all`
        }
        else {
          apiUrl = `/masters/getSubjectByExamId/${payload?.examId}?statusId=${payload.obj.status.statusId}&filterType=${payload.obj.status.name}`
        }
      } else {
        apiUrl = `/masters/getSubjectByExamId/${payload?.examId}?statusId=2&filterType=approved`

      }
    }
    else if (roleId == 3) {
      if ((payload?.obj?.status?.statusId != -1) && payload.obj.status != undefined) {
        if (payload.obj.status.name == 'all') {
          apiUrl = `/masters/getSubjectByExamId/${payload?.examId}?filterType=all`

        } else {
          apiUrl = `/masters/getSubjectByExamId/${payload?.examId}?statusId=${payload.obj.status.statusId}&filterType=${payload.obj.status.name}`

        }
      } else {
        apiUrl = `/masters/getSubjectByExamId/${payload?.examId}?statusId=2&filterType=approved`
      }
    }
    let getAll = await axios.post(apiUrl, payload?.obj).then(async (res) => {
      if (res.data.status) {
        thunk.dispatch(addBreadCrumbData(res.data.data?.breadcrumbs))
        return res.data.data
      }
    })
    let obj = {
      id: payload.examId,
      data: getAll
    }
    return obj

  } catch (error) {
  }
})

//  get All QuestionType  
export const getAllQuestionType = createAsyncThunk("Masters/QuestionType", async () => {
  try {
    let getAll = await axios.get('/masters/getTypeOfQuestion').then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getAll

  } catch (error) {
  }
})

//  get All Type of Test  
export const getAllTypeOfTest = createAsyncThunk("Masters/TypeOfTest", async () => {
  try {
    let getAll = await axios.get('/masters/getAllTypeOfTest').then((res) => {
      if (res.data.status) {
        return res.data.data
      }
    })
    return getAll

  } catch (error) {
  }
})

//  get All currentUser  
export const getCurrentUser = createAsyncThunk("Masters/getCurrentUser", async (payload) => {
  try {
    let getUser = await axios.get('/masters/getCurrentUser').then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getUser

  } catch (error) {
  }
})

//  get All currentUser  
export const addBreadCrumbData = createAsyncThunk("Masters/getCurrentUser", async (payload) => {
  try {
    payload['exam'] = 'Exam'

    return payload

  } catch (error) {
  }
})

//  get All create exam group  
export const createExamGroup = createAsyncThunk("Masters/createExamGroup", async (payload, thunk) => {
  try {
    let getUser = await axios.post('/masters/createExamGroup', payload.data).then(async (res) => {
      if (res.data.status) {
        message.success(res.data.message)
        await thunk.dispatch(getAllExamGroup(payload.obj))
        return res.data
      }
    })
    return getUser

  } catch (error) {
    message.error(error.response.data.message)
  }
})

//  get All exam group  
export const getAllExamGroup = createAsyncThunk("Masters/getAllExamGroup", async (payload) => {
  try {
    let getUser = await axios.post('/masters/getAllExamGroup', payload).then((res) => {
      if (res.data.status) {
        return res.data.data
      }
    })

    return getUser

  } catch (error) {
  }
})

//  get exam group by Id 
export const getExamGroupById = createAsyncThunk("Masters/getExamGroupById", async (payload) => {
  try {
    let getUser = await axios.get(`/masters/getExamGroupById/${payload}`).then((res) => {
      if (res.data.status) {
        return res.data
      }
    })
    return getUser
  } catch (error) {
  }
})

export const updateExamGroup = createAsyncThunk("Masters/updateExamGroup", async (payload, thunk) => {
  try {
    let updateUser = await axios.put(`/masters/updateExamGroup/${payload.id}`, payload.data).then(async (res) => {
      if (res.data.status) {
        message.success(res.data.message)

        await thunk.dispatch(getAllExamGroup(payload.obj))
        return res.data


      }
    })
    return updateUser
  } catch (error) {
    message.error(error.response.data.message)

  }
})
export const deleteExamGroup = createAsyncThunk("Masters/deleteExamGroup", async (payload, thunk) => {
  try {
    let updateUser = await axios.delete(`/masters/deleteExamGroup/${payload.id}`).then(async (res) => {
      if (res.data.status) {
        message.success(res.data.message)

        await thunk.dispatch(getAllExamGroup(payload.obj))
        return res.data
      }
    })
    return updateUser
  } catch (error) {
    message.error(error.response.data.message)

  }
})

export const getExamByExamGroupId = createAsyncThunk("Masters/getExamByExamGroupId", async (payload, thunk) => {
  try {
    let apiUrl = `/masters/getExamByExamGroupId/${payload?.examGroupId}`
    let getAll = await axios.post(apiUrl, payload?.obj).then(async (res) => {
      if (res.data.status) {
        // thunk.dispatch(addBreadCrumbData(res.data.data?.breadcrumbs))
        return res.data.data
      }
    })
    let obj = {
      id: payload.examId,
      data: getAll
    }
    return obj
  } catch (error) {
  }
})


const initialState = {
  roleMaster: [],
  subjectData: [],
  difficultLevelData: [],
  chapterData: [],
  examData: [],
  typeOfQuestion: [],
  subjectByChapter: {},
  subjectDataByExam: {},
  objSubjectByChapter: {},
  objExamBySubject: {},
  typeOfTest: [],
  isLoading: false,
  subjectBreadCrumbData: {},
  chapterBreadCrumbData: {},
  currentUserData: {

  },
  currentBreadCrumbData: {},
  examGroupData: [],
  examGroupById: {},
  examGroupDataCount: 0,
  examDataByGroup: []
}

const MasterSlice = createSlice({
  name: 'Masters',
  initialState,
  reducers: {
  },
  extraReducers: {

    [getAllRole.pending]: (state) => {

    },
    [getAllRole.fulfilled]: (state, action) => {
      state.roleMaster = action.payload.filter((data, index) => data.id != 2)
    },
    [getAllRole.rejected]: (state) => {

    },

    [getAllSubject.pending]: (state) => {

    },
    [getAllSubject.fulfilled]: (state, action) => {
      state.subjectData = action.payload?.getSubjectData
    },
    [getAllSubject.rejected]: (state) => {

    },

    [getAllDifficulty.pending]: (state) => {

    },
    [getAllDifficulty.fulfilled]: (state, action) => {
      state.difficultLevelData = action.payload
    },
    [getAllDifficulty.rejected]: (state) => {

    },
    [getChapterBySubject.pending]: (state, action) => {
      state.isLoading = true

    },
    [getChapterBySubject.fulfilled]: (state, action) => {
      state.isLoading = false
      state.subjectByChapter = action.payload.data
      state.objSubjectByChapter[action.payload.id] = action.payload.data.getChapterData
      state.chapterBreadCrumbData = action.payload.data.breadcrumbs
    },
    [getChapterBySubject.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getAllExam.pending]: (state, action) => {
      state.isLoading = true

    },
    [getAllExam.fulfilled]: (state, action) => {
      state.isLoading = false
      state.examData = action.payload.getExamData

    }, [getAllExam.rejected]: (state, action) => {
      state.isLoading = false

    }, [getAllQuestionType.rejected]: (state, action) => {
      state.isLoading = false

    },
    [getAllChapter.pending]: (state) => {

    },
    [getAllChapter.fulfilled]: (state, action) => {
      state.chapterData = action.payload?.getChapterData
    },
    [getAllChapter.rejected]: (state) => {

    },
    [getSubjectByExamId.pending]: (state, action) => {
      state.isLoading = true
    },
    [getSubjectByExamId.fulfilled]: (state, action) => {
      state.isLoading = false
      state.subjectDataByExam = action.payload.data
      state.subjectBreadCrumbData = action.payload.data.breadcrumbs

      state.objExamBySubject[action.payload.id] = action.payload.data

    },
    [getSubjectByExamId.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getAllTypeOfTest.pending]: (state, action) => {
      state.isLoading = true
    },
    [getAllTypeOfTest.fulfilled]: (state, action) => {
      state.isLoading = false
      state.typeOfTest = action.payload

    },
    [getAllTypeOfTest.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getCurrentUser.pending]: (state, action) => {
    },
    [getCurrentUser.fulfilled]: (state, action) => {

      state.currentUserData = action.payload

    },
    [getCurrentUser.rejected]: (state, action) => {
    },
    [addBreadCrumbData.pending]: (state, action) => {
      state.isLoading = true
    },
    [addBreadCrumbData.fulfilled]: (state, action) => {

      state.isLoading = false
      state.currentBreadCrumbData = action.payload

    },
    [addBreadCrumbData.rejected]: (state, action) => {
      state.isLoading = false
    },
    [createExamGroup.pending]:(state,action)=>{
      state.isLoading = true
    },
    [createExamGroup.fulfilled]:(state,action)=>{
      state.isLoading = false
      state.createExamGroup = action.payload
    },
    [createExamGroup.rejected]:(state,action)=>{
      state.isLoading = false
    },
    [getAllExamGroup.pending]: (state, action) => {
      state.examGroupData = []
      state.isLoading = true
    },
    [getAllExamGroup.fulfilled]: (state, action) => {
      state.isLoading = false
      state.examGroupData = action.payload.allData
      state.examGroupDataCount = action.payload.count.totalCount
    },
    [getAllExamGroup.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getExamGroupById.pending]: (state, action) => {
      state.examGroupById = {}
      state.isLoading = true
    },
    [getExamGroupById.fulfilled]: (state, action) => {
      state.examGroupById = action.payload.data
      state.isLoading = false

    },
    [getExamGroupById.rejected]: (state, action) => {
      state.isLoading = false
    },

    [updateExamGroup.pending]: (state, action) => {
      // state.examGroupById = {}
      state.isLoading = true
    },
    [updateExamGroup.fulfilled]: (state, action) => {
      // state.examGroupById = action.payload.data
      state.isLoading = false

    },
    [updateExamGroup.rejected]: (state, action) => {
      state.isLoading = false
    },
    [deleteExamGroup.pending]: (state, action) => {
      state.isLoading = true
    },
    [deleteExamGroup.fulfilled]: (state, action) => {
      state.isLoading = false
    },
    [deleteExamGroup.rejected]: (state, action) => {
      state.isLoading = false
    },

    [updateExamGroup.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getExamByExamGroupId.pending]: (state, action) => {
      state.isLoading = true
    },
    [getExamByExamGroupId.fulfilled]: (state, action) => {
      state.examDataByGroup = action.payload.data
      state.isLoading = false
    },
    [getExamByExamGroupId.rejected]: (state, action) => {
      state.isLoading = false
    },


  }
})
export default MasterSlice.reducer